<template>
  <v-container class="admin-feedback-container">
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field v-model="searchQuery" label="Search Feedback" class="mb-4" variant="solo"></v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select v-model="selectedCategory" :items="categories" label="Filter by Category" class="mb-4"
          variant="solo"></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select v-model="selectedTimeFrame" :items="timeFrames" label="Filter by Time" class="mb-4"
          variant="solo"></v-select>
      </v-col>
    </v-row>

    <v-card class="rating-overview-card d-flex flex-column mx-auto py-4" elevation="8">
      <div class="d-flex justify-center text-h6">Rating Overview</div>
      <div class="d-flex align-center flex-column my-3">
        <div class="text-h4">
          {{ averageRating }}
          <span class="text-subtitle-1 ml-n1">/5</span>
        </div>
        <v-rating :model-value="averageRating" color="yellow-darken-3" half-increments readonly></v-rating>
        <div class="px-3">{{ totalRatings }} ratings</div>
      </div>
      <v-list bg-color="transparent" class="d-flex flex-column-reverse" density="compact">
        <v-list-item v-for="(count, i) in ratingCounts" :key="i">
          <v-progress-linear :model-value="(count / totalRatings) * 100" class="mx-n5" color="yellow-darken-3"
            height="10" rounded></v-progress-linear>
          <template v-slot:prepend>
            <span>{{ 5 - i }}</span>
            <v-icon class="mx-3" icon="mdi-star"></v-icon>
          </template>
          <template v-slot:append>
            <div class="rating-values">
              <span class="d-flex justify-end">{{ count }}</span>
            </div>
          </template>
        </v-list-item>
      </v-list>
    </v-card>

    <v-list class="feedback-list mt-4">
      <v-list-item v-for="(feedback, index) in filteredFeedbacks" :key="index" class="feedback-item">
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center justify-space-between feedback-title">
            <span>{{ feedback['Your Name (Optional)'] || 'Anonymous' }}</span>
            <v-chip color="primary">{{ feedback.Category }}</v-chip>
          </v-list-item-title>
          <v-list-item-subtitle class="feedback-subtitle">
            <v-rating hover readonly :length="5" :size="26"
              :model-value="parseInt(feedback['How would you rate your experience'])" color="primary"
              active-color="green" />
          </v-list-item-subtitle>
          <v-list-item-content class="mt-2 feedback-content">
            <p>{{ feedback['Your Feedback'] }}</p>
            <small class="text-grey">{{ feedback.Timestamp }}</small>
          </v-list-item-content>
        </v-list-item-content>
        <v-divider v-if="index < filteredFeedbacks.length - 1" class="my-3 border-opacity-100"></v-divider>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import { ref, computed } from 'vue'
import moment from 'moment'

export default {
  name: 'AdminFeedbacks',
  props: {
    feedbacks: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    timeFrames: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const searchQuery = ref('')
    const selectedCategory = ref('All Categories')
    const selectedTimeFrame = ref('Current Month')

    const filteredFeedbacks = computed(() => {
      return props.feedbacks.filter(feedback => {
        const matchesSearch = feedback['Your Feedback'].toLowerCase().includes(searchQuery.value.toLowerCase())
        const matchesCategory = selectedCategory.value === 'All Categories' || feedback.Category === selectedCategory.value
        const feedbackDate = moment(feedback.Timestamp, 'M/D/YYYY H:mm:ss')

        let matchesTimeFrame = true
        switch (selectedTimeFrame.value) {
          case 'Today':
            matchesTimeFrame = feedbackDate.isSame(moment(), 'day')
            break
          case 'Current Week':
            matchesTimeFrame = feedbackDate.isSame(moment(), 'week')
            break
          case 'Last Week':
            matchesTimeFrame = feedbackDate.isSame(moment().subtract(1, 'weeks'), 'week')
            break
          case 'Current Month':
            matchesTimeFrame = feedbackDate.isSame(moment(), 'month')
            break
          case 'Last Month':
            matchesTimeFrame = feedbackDate.isSame(moment().subtract(1, 'months'), 'month')
            break
          case 'Current Year':
            matchesTimeFrame = feedbackDate.isSame(moment(), 'year')
            break
        }

        return matchesSearch && matchesCategory && matchesTimeFrame
      })
    })

    const averageRating = computed(() => {
      const totalRating = props?.feedbacks?.reduce((sum, feedback) => sum + parseInt(feedback['How would you rate your experience']), 0)
      return (totalRating / props.feedbacks.length).toFixed(1)
    })

    const totalRatings = computed(() => props.feedbacks.length)

    const ratingCounts = computed(() => {
      const counts = [0, 0, 0, 0, 0]
      props.feedbacks.forEach(feedback => {
        const rating = parseInt(feedback['How would you rate your experience'])
        counts[5 - rating]++
      })
      return counts
    })

    return {
      searchQuery,
      selectedCategory,
      selectedTimeFrame,
      filteredFeedbacks,
      averageRating,
      totalRatings,
      ratingCounts,
    }
  },
}
</script>

<style scoped>
.admin-feedback-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.rating-overview-card {
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;
  padding: 16px;
}

.feedback-list {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
  padding: 16px;
}

.feedback-item {
  padding: 16px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.feedback-item:hover {
  background-color: #f0f4f8;
}

.feedback-title {
  font-weight: 500;
  font-size: 1.2rem;
  font-family: 'Roboto', sans-serif;
  color: #333333;
}

.feedback-subtitle {
  font-weight: 400;
  font-size: 1rem;
  color: #666666;
}

.feedback-content {
  margin-top: 8px;
  font-size: 0.95rem;
  color: #444444;
  font-family: 'Roboto', sans-serif;
}

.v-divider {
  margin: 16px 0;
  height: 1px;
  background-color: #e0e0e0;
}

@media (max-width: 600px) {
  .admin-feedback-container {
    padding: 15px;
  }

  .rating-overview-card {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .feedback-card {
    padding: 15px;
  }

  .custom-toolbar-title {
    font-size: 1.2rem;
  }

  .submit-button {
    width: 100%;
  }
}
</style>

<!--
This component, AdminFeedbacks, provides a comprehensive interface for administrators to view and filter user feedback. 

Users can interact with the following features:

1. Search Feedback: A text field where users can input keywords to search through the feedback comments. This helps in quickly finding specific feedback based on the content.

2. Filter by Category: A dropdown menu that allows users to filter feedback based on predefined categories. This helps in narrowing down feedback to specific areas of interest.

3. Filter by Time: Another dropdown menu that enables users to filter feedback based on different time frames such as Today, Current Week, Last Week, Current Month, Last Month, and Current Year. This is useful for analyzing feedback trends over different periods.

The component also provides a visual summary of the feedback:

1. Rating Overview: Displays the average rating out of 5, the total number of ratings, and a visual representation of the rating distribution using a progress bar for each rating level (1 to 5 stars). This gives a quick snapshot of the overall user satisfaction.

2. Feedback List: A detailed list of individual feedback entries. Each entry shows:
  - The name of the user (or 'Anonymous' if not provided).
  - The category of the feedback.
  - The rating given by the user, displayed as stars.
  - The feedback comment.
  - The timestamp of when the feedback was submitted.

The feedback list is dynamically filtered based on the search query, selected category, and selected time frame. This ensures that users can easily find relevant feedback without having to sift through all entries manually.

The component enhances user experience by providing a clean, organized, and interactive way to manage and analyze feedback, making it easier for administrators to understand user sentiments and make informed decisions.
-->
