<template>
    <div class="content-wrapper">
        <!-- Elegant Table Layout for Larger Screens -->
        <div v-if="isLargeScreen" class="table-wrapper">
            <table class="custom-table fade-in">
                <thead>
                    <tr>
                        <th class="text-center">First Name</th>
                        <th class="text-center">Last Name</th>
                        <th class="text-center">Email</th>
                        <th class="text-center">Groups</th>
                        <th class="text-center">Roles</th>
                        <th class="text-center">Campus</th>
                        <th class="text-center">Options</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.id" class="clickable-row">
                        <td>{{ user.firstName }}</td>
                        <td>{{ user.lastName }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ formatArray(user.groups) }}</td>
                        <td>{{ formatArray(user.roles) }}</td>
                        <td>{{ user.campusName }}</td>
                        <td>
                            <i class="mdi mdi-pencil text-green mr-2" @click.stop="handleEdit(user)"
                                style="cursor: pointer;"></i>
                            <i class="mdi mdi-delete text-red" @click.stop="handleDelete(user)"
                                style="cursor: pointer;"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Elegant Card Layout for Smaller Screens -->
        <div v-else class="card-wrapper">
            <div v-for="user in users" :key="user.id" class="user-card fade-in">
                <div class="user-card-header">
                    <div class="user-name">
                        <i class="mdi mdi-account mr-1"></i>
                        <span>{{ user.firstName }} {{ user.lastName }}</span>
                    </div>
                    <div class="user-options">
                        <i class="mdi mdi-pencil text-green mr-2" @click.stop="handleEdit(user)"></i>
                        <i class="mdi mdi-delete text-red" @click.stop="handleDelete(user)"></i>
                    </div>
                </div>

                <div class="user-card-content">
                    <div class="user-detail">
                        <i class="mdi mdi-email mr-1"></i>
                        <span>{{ user.email }}</span>
                    </div>
                    <div class="user-detail">
                        <i class="mdi mdi-group mr-1"></i>
                        <span>{{ formatArray(user.groups) }}</span>
                    </div>
                    <div class="user-detail">
                        <i class="mdi mdi-briefcase mr-1"></i>
                        <span>{{ formatArray(user.roles) }}</span>
                    </div>
                    <div class="user-detail">
                        <i class="mdi mdi-map-marker mr-1"></i>
                        <span>{{ user.campusName }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onUnmounted } from 'vue';

export default defineComponent({
    name: 'AdminUsers',
    props: {
        users: {
            type: Array,
            required: true,
        },
        onEdit: {
            type: Function,
            required: true,
        },
        onDelete: {
            type: Function,
            required: true,
        },
    },
    setup(props) {
        const windowWidth = ref(window.innerWidth);

        const updateWidth = () => {
            windowWidth.value = window.innerWidth;
        };

        onMounted(() => {
            window.addEventListener('resize', updateWidth);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', updateWidth);
        });

        const isLargeScreen = computed(() => windowWidth.value > 800);
        const formatArray = (arr) => arr.join(', ');

        const handleEdit = (user) => {
            props.onEdit(user);
        };

        const handleDelete = (user) => {
            props.onDelete(user);
        };

        return {
            isLargeScreen,
            formatArray,
            handleEdit,
            handleDelete,
        };
    },
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Table Styling */
.table-wrapper {
    width: 100%;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.custom-table thead {
    background-color: #4caf50;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.95rem;
}

.custom-table th,
.custom-table td {
    padding: 14px 18px;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
}

.custom-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.clickable-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.text-green {
    color: #4caf50;
}

.text-red {
    color: #f44336;
}

/* Card Styling */
.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.user-card {
    font-family: 'Inter', sans-serif;
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 1rem;
}

.user-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.user-name {
    font-weight: 600;
    font-size: 1rem;
    color: #444;
}

.user-options i {
    cursor: pointer;
    font-size: 1.25rem;
}

.user-card-content {
    border-top: 1px solid #e0e0e0;
    padding-top: 12px;
    display: grid;
    gap: 10px;
}

.user-detail {
    font-size: 0.95rem;
    color: #666;
    display: flex;
    align-items: center;
}

.mr-1 {
    margin-right: 6px;
}
</style>