<template>
  <div class="content-wrapper">
    <!-- Elegant Table Layout for Larger Screens -->
    <div v-if="isLargeScreen" class="table-wrapper">
      <table class="custom-table fade-in">
        <thead>
          <tr>
            <th class="text-center">Description</th>
            <th class="text-center">Date & Time</th>
            <th class="text-center">Category</th>
            <th class="text-center">Location</th>
            <th class="text-center">Creator</th>
            <th class="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="incident in filteredIncidents" :key="incident.id" @click="handleClick(incident)" class="clickable-row">
            <td>{{ incident.description }}</td>
            <td>
              <span>{{ formatDate(incident.creationTime) }}</span>
              <br />
              <span>{{ formatTime(incident.creationTime) }}</span>
            </td>
            <td>
              <span>{{ getCategoryMain(incident.incidentCategoryName) }}</span>
              <br />
              <span v-if="getCategoryDetail(incident.incidentCategoryName)">({{ getCategoryDetail(incident.incidentCategoryName) }})</span>
            </td>
            <td>
              <span>{{ getLocationMain(incident.location.locationName) }}</span>
              <br />
              <span>{{ getLocationDetail(incident.location.locationName) }}</span>
            </td>
            <td>{{ incident.creatorName }}</td>
            <td>
              <span :class="`status-chip ${incident.state.toLowerCase()}`">{{ incident.state }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Elegant Card Layout for Smaller Screens -->
    <div v-else class="card-wrapper">
      <div v-for="incident in filteredIncidents" :key="incident.id" class="incident-card fade-in" @click="handleClick(incident)">
        <div class="incident-card-header">
          <div class="incident-date">
            <i class="mdi mdi-calendar mr-1"></i>
            <span>{{ formatDateTime(incident.creationTime) }}</span>
          </div>
          <span :class="`status-chip ${incident.state.toLowerCase()}`">{{ incident.state }}</span>
        </div>

        <div class="incident-card-content">
          <div class="incident-detail">
            <i class="mdi mdi-text mr-1"></i>
            <span>{{ incident.description }}</span>
          </div>
          <div class="incident-detail">
            <i class="mdi mdi-format-list-bulleted mr-1"></i>
            <span>{{ incident.incidentCategoryName }}</span>
          </div>
          <div class="incident-detail">
            <i class="mdi mdi-map-marker mr-1"></i>
            <span>{{ incident.location.locationName }}</span>
          </div>
          <div class="incident-detail">
            <i class="mdi mdi-account mr-1"></i>
            <span>{{ incident.creatorName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue'
  import moment from 'moment'

  export default defineComponent({
    name: 'UserIncidents',
    props: {
      incidents: {
        type: Array,
        required: true,
      },
      clickHandler: {
        type: Function,
        default: null,
      },
      poiNameFilter: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      const windowWidth = ref(window.innerWidth)

      const updateWidth = () => {
        windowWidth.value = window.innerWidth
      }

      onMounted(() => {
        window.addEventListener('resize', updateWidth)
      })

      onUnmounted(() => {
        window.removeEventListener('resize', updateWidth)
      })

      const isLargeScreen = computed(() => windowWidth.value > 800)
      const formatDate = date => moment(date).format('DD.MM.YYYY')
      const formatTime = date => moment(date).format('HH:mm')
      const formatDateTime = date => moment(date).format('DD.MM.YYYY HH:mm')
      const getCategoryMain = categoryName => categoryName.split(' - ')[0]
      const getCategoryDetail = categoryName => categoryName.split(' - ')[1] || ''
      const getLocationMain = locationName => locationName.split(',')[0]
      const getLocationDetail = locationName => locationName.split(',')[1] || ''

      const filteredIncidents = computed(() => {
        return props.incidents.filter(incident => {
          const locationName = incident.location.locationName.toLowerCase()
          const poiNameFilter = props.poiNameFilter.toLowerCase()
          return locationName.includes(poiNameFilter) || locationName.includes('near ' + poiNameFilter)
        })
      })

      const handleClick = incident => {
        if (props.clickHandler) {
          incident = JSON.parse(JSON.stringify(incident))
          props.clickHandler(incident)
        } else {
          console.warn('clickHandler is not defined in props.')
        }
      }

      return {
        isLargeScreen,
        formatDate,
        formatTime,
        formatDateTime,
        getCategoryMain,
        getCategoryDetail,
        getLocationMain,
        getLocationDetail,
        handleClick,
        filteredIncidents,
      }
    },
  })
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

  .fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Table Styling */
  .table-wrapper {
    width: 100%;
  }

  .custom-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }

  .custom-table thead {
    background-color: #4caf50;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.95rem;
  }

  .custom-table th,
  .custom-table td {
    padding: 14px 18px;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
  }

  .custom-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }

  .clickable-row {
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .clickable-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .status-chip {
    padding: 4px 8px;
    border-radius: 12px;
    color: #fff;
    font-weight: bold;
  }

  .status-chip.open {
    background-color: #f44336;
  }

  .status-chip.closed {
    background-color: #4caf50;
  }

  .status-chip.pending {
    background-color: #ff9800;
  }

  /* Card Styling */
  .card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .incident-card {
    font-family: 'Inter', sans-serif;
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
  }

  .incident-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .incident-date {
    font-weight: 600;
    font-size: 1rem;
    color: #444;
  }

  .incident-status {
    font-size: 0.875rem;
    padding: 6px 10px;
    border-radius: 5px;
    font-weight: 500;
  }

  .incident-card-content {
    border-top: 1px solid #e0e0e0;
    padding-top: 12px;
    display: grid;
    gap: 10px;
  }

  .incident-detail {
    font-size: 0.95rem;
    color: #666;
    display: flex;
    align-items: center;
  }

  .mr-1 {
    margin-right: 6px;
  }
</style>
