<template>
  <div class="chat">
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Contacts' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container fluid class="py-6">
      <!-- Search Bar -->
      <div class="search-bar-container">
        <input type="text" v-model="searchQuery" placeholder="Search by name, email, or job" class="search-bar" />
      </div>

      <!-- User List -->
      <div class="custom-list">
        <div v-for="(item, index) in filteredUsers" :key="index" class="custom-list-item"
          :class="{ disabled: item.email === ownUser?.email }" @click="item.email !== ownUser?.email && openChat(item)">
          <template v-if="item.type === 'subheader'">
            <div class="subheader">{{ item.title }}</div>
          </template>
          <template v-else-if="item.type === 'divider'">
            <hr class="divider" />
          </template>
          <template v-else>
            <img :src="item.prependAvatar" class="avatar" />
            <div class="content">
              <div class="title" v-html="item.title"></div>
              <div class="subtitle" v-html="item.subtitle"></div>
            </div>
            <v-icon v-if="item.email !== ownUser?.email" @click="toggleFavorite(item)" class="favorite-icon">
              {{ item.isFavorite ? 'mdi-star' : 'mdi-star-outline' }}
            </v-icon>
          </template>
        </div>
      </div>
    </v-container>

    <v-snackbar v-model="snackbar" timeout="3000" top>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted, computed, watch } from 'vue'
import { debounce } from 'lodash'
import Loader from '@/components/general/Loader.vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { getUsers } from '@/controllers/BackboneAPI'
import { getUserProfiles, getFile, getDataFromBlob } from '@/controllers/BaseController'
import lodash from 'lodash'
import user_avatar from '@/assets/General/user_avatar.png'
import { getStoredItem, setStoredItem, removeStoredItem, storeBlob, retrieveBlob } from '@/services/utils'
import { useRouter } from 'vue-router'
import { useOAuthService } from '@/services/OAuthService'

export default defineComponent({
  name: 'ChatUsersList',
  components: {
    Loader,
  },
  setup() {
    const logo = ref('')
    const OAuthService = useOAuthService()
    const showLoader = ref(false)
    const users = ref([])
    const ownUser = ref(null)
    const searchQuery = ref('')
    const debouncedSearchQuery = ref('')
    const snackbar = ref(false)
    const snackbarText = ref('')
    const router = useRouter()

    const openChat = async user => {
      user = JSON.parse(JSON.stringify(user))
      await storeBlob('selectedUser', user)
      await storeBlob('ownUser', JSON.parse(JSON.stringify(ownUser.value)))
      router.push({
        name: 'Chat',
        params: { name: user.title, email: user?.email },
      })
    }

    const filteredUsers = computed(() => {
      const query = debouncedSearchQuery.value.toLowerCase()
      return users.value
        .filter(
          user =>
            user.title.toLowerCase().includes(query) ||
            user.subtitle.toLowerCase().includes(query) ||
            (user.profile?.job?.toLowerCase().includes(query) ?? false)
        )
        .sort((a, b) => {
          if (a.isFavorite && !b.isFavorite) return -1
          if (!a.isFavorite && b.isFavorite) return 1
          return a.title.localeCompare(b.title)
        })
    })

    const updateSearchQuery = debounce(query => {
      debouncedSearchQuery.value = query
    }, 300)

    watch(searchQuery, newQuery => {
      updateSearchQuery(newQuery)
    })

    const toggleFavorite = async user => {
      user.isFavorite = !user.isFavorite
      if (user.isFavorite) {
        await setStoredItem(user?.email, true)
        snackbarText.value = `${user.title} added to favorites`
      } else {
        await removeStoredItem(user?.email)
        snackbarText.value = `${user.title} removed from favorites`
      }
      snackbar.value = true
      sortUsers()
    }

    const sortUsers = () => {
      users.value = users.value.slice().sort((a, b) => {
        if (a.isFavorite && !b.isFavorite) return -1
        if (!a.isFavorite && b.isFavorite) return 1
        return a.title.localeCompare(b.title)
      })
    }

    onMounted(async () => {
      try {
        logo.value = await getToolbarLogoImage()
        showLoader.value = true

        const [usersList, userProfiles] = await Promise.all([getUsers(), getUserProfiles()])

        let formattedUsers = await Promise.all(
          usersList.map(async user => {
            const email = user?.email
            const profile = userProfiles.find(profile => profile?.email === email)

            const title =
              !user?.firstName || !user?.lastName || user?.firstName.toLowerCase() === 'firstname' || user?.lastName.toLowerCase() === 'lastname'
                ? email
                  .split('@')[0]
                  .replace(/\./g, ' ')
                  .replace(/\b\w/g, char => char.toUpperCase())
                : `${user?.firstName} ${user?.lastName}`

            const subtitle = profile?.job ? `${email}<br><span class="text-primary">Position</span> &mdash; ${profile.job}` : email

            let prependAvatar = user_avatar
            if (profile) {
              const profileImage = profile?.files?.entities.find(file => file.type === 'profileImage')
              if (profileImage) {
                const fileData = await getFile(profileImage.id)
                const base64ImageData = await getDataFromBlob(fileData)
                if (base64ImageData.startsWith('data:image')) {
                  prependAvatar = base64ImageData
                }
              }
            }

            const isFavorite = (await retrieveBlob(email)) === true

            return {
              ...user,
              profile,
              title,
              subtitle,
              prependAvatar,
              isFavorite,
            }
          })
        )
        formattedUsers = lodash.filter(
          formattedUsers,
          user =>
            user.email?.toLowerCase().includes('khizar') ||
            user.email?.toLowerCase().includes('anny') ||
            user.email?.toLowerCase().includes('maximilian') ||
            user.email?.toLowerCase().includes('wilfried') ||
            user.email?.toLowerCase().includes('qa') ||
            user.email?.toLowerCase().includes('liana') ||
            user.email?.toLowerCase().includes('graphic')
        )
        let ownUserProfile = await OAuthService.getUserProfile()
        ownUser.value = formattedUsers.find(user => user.email === ownUserProfile.email)
        users.value = formattedUsers
        sortUsers()
      } catch (error) {
        console.error(error)
      } finally {
        showLoader.value = false
      }
    })

    return {
      logo,
      users,
      showLoader,
      searchQuery,
      filteredUsers,
      snackbar,
      snackbarText,
      toggleFavorite,
      openChat,
      ownUser,
    }
  },
})
</script>

<style scoped>
.chat {
  padding: 1rem;
}

/* Toolbar Enhancement */
.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

/* Search Bar Enhancement */
.search-bar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.search-bar {
  width: 100%;
  max-width: 600px;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  font-size: 1rem;
}

.search-bar:focus {
  outline: none;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #f0f0f0;
}

/* List Items Enhancement */
.custom-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.custom-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.custom-list-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.custom-list-item.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 1.5rem;
  object-fit: cover;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
}

.subtitle {
  font-size: 1rem;
  color: #666;
  margin-top: 0.25rem;
}

.subheader {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1rem 0;
  color: #333;
  border-bottom: 1px solid #eee;
}

.divider {
  height: 1px;
  background-color: #ddd;
  margin: 1rem 0;
}

.favorite-icon {
  cursor: pointer;
  color: #f7b731;
  margin-left: auto;
}

/* Snackbar Enhancement */
.v-snackbar {
  bottom: 50%;
  background-color: #323232;
  color: #ffffff;
  border-radius: 8px;
}

/* Responsiveness */
@media (max-width: 768px) {

  /* Compact styling for smaller screens */
  .custom-list-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.75rem;
    /* Reduce padding */
  }

  .avatar {
    width: 45px;
    /* Smaller avatar */
    height: 45px;
    margin-bottom: 1rem;
  }

  .content {
    align-items: flex-start;
  }

  .title {
    font-size: 1.2rem;
    /* Smaller title */
  }

  .subtitle {
    font-size: 0.9rem;
  }

  .custom-list {
    gap: 1rem;
    /* Reduce gap between list items */
  }

  .search-bar {
    padding: 0.5rem 1rem;
    /* Smaller padding for search bar */
    max-width: 100%;
  }
}
</style>
<!--
ChatUsersList Component

This component provides a user interface for displaying and interacting with a list of chat users. 

Toolbar: At the top, there is a toolbar that includes a logo and a title "Contacts". The toolbar is styled to have a semi-transparent background with a blur effect, making it visually appealing.

Loader: A loader component is displayed when data is being fetched or processed, providing visual feedback to the user that an operation is in progress.

Search Bar: Below the toolbar, there is a search bar where users can type to search for contacts by name, email, or job title. The search bar is styled with rounded corners and a shadow effect, and it provides real-time search results as the user types.

User List: The main section of the component displays a list of users. Each user item includes an avatar, title, subtitle, and a favorite icon. Users can click on an item to open a chat with that user. If the user is the same as the logged-in user, the item is disabled and styled with reduced opacity.

Favorite Icon: Each user item has a star icon that users can click to mark or unmark the user as a favorite. When a user is marked as a favorite, the icon changes to a filled star, and a snackbar notification is shown at the top of the screen indicating the action taken (added to or removed from favorites).

Snackbar: A snackbar notification appears at the top of the screen to provide feedback on actions such as adding or removing a user from favorites. The snackbar is styled with a dark background and white text, and it disappears automatically after a few seconds.

Dynamic Behaviors: 
- The search bar updates the list of users in real-time as the user types.
- Clicking on a user item (except the logged-in user) opens a chat with that user.
- Clicking on the favorite icon toggles the favorite status of the user and shows a snackbar notification.

Visual Feedback: 
- Loader component indicates data fetching or processing.
- Snackbar notifications provide feedback on user actions.
- Favorite icon changes state based on whether the user is marked as a favorite.

Overall, this component enhances the user experience by providing a visually appealing and interactive interface for managing and interacting with chat contacts.
-->
