<template>
  <div class="chat-view" style="background-color: #f8f9fa; max-height: 100%; height: 89vh">
    <Loader :loading="showLoader" />
    <!-- <v-toolbar class="custom-toolbar mb-4" flat dense>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">
        {{ 'Chat' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar> -->

    <!-- <v-container fluid class="py-6 chat-container"> -->
    <Loader :loading="showLoader" />
    <!-- <v-alert
      variant="tonal"
      v-if="showNotificationAlert"
      type="info"
      prominent
      color="blue lighten-4"
      class="notification-alert"
      transition="slide-y-transition"
    >
      <div class="alert-content">
        <span class="alert-text">Enable Notifications?</span>
        <v-btn class="alert-btn" @click="handleEnableNotifications">Yes</v-btn>
        <v-btn class="alert-btn" @click="hideNotificationAlert">No</v-btn>
      </div>
    </v-alert> -->
    <v-alert v-if="showNotificationAlert" type="info" transition="slide-y-transition"
      color="linear-gradient(135deg, #f9f9f9, #ffffff)" icon="mdi-bell" density="compact" class="notification-alert">
      Enable Notifications?
      <v-btn size="small" color="success" variant="tonal" class="ml-4" @click="handleEnableNotifications">Yes</v-btn>
      <v-btn size="small" color="error" variant="tonal" class="ml-4" @click="hideNotificationAlert">No</v-btn>
    </v-alert>

    <vue-advanced-chat :current-user-id="ownUser?.email" :messages="JSON.stringify(messages)"
      :messages-loaded="messagesLoaded" :show-footer="true" :show-send-icon="true" :show-audio="false"
      :single-room="true" :show-files="false" :show-add-room="false" :rooms="JSON.stringify(rooms)"
      :show-new-messages-divider="true" :show-reaction-emojis="false" @send-message-reaction="sendMessageReaction"
      :show-emojis="false" @send-message="sendMessage" @edit-message="editMessage" @delete-message="deleteMessage"
      height="calc(100vh - 150px)" />
    <!-- </v-container> -->
  </div>
</template>

<script>
import { ref, defineComponent, onMounted } from 'vue'
import Loader from '@/components/general/Loader.vue'
import { retrieveBlob, requestNotificationPermission, checkNotificationPermission } from '@/services/utils'
import { useRoute } from 'vue-router'
import { register } from 'vue-advanced-chat'
import lodash from 'lodash'
import user_avatar from '@/assets/General/user_avatar.png'
import user_avatar2 from '@/assets/General/user_avatar2.png'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { getChatOfUser, getAllChats, postChatMessage, getChatById, deleteChatById, updateChatById } from '@/controllers/BaseController'
import moment from 'moment'

register()

export default defineComponent({
  name: 'Chat',
  components: {
    Loader,
  },
  setup() {
    const logo = ref('')
    const showLoader = ref(false)
    const selectedUser = ref(null)
    const ownUser = ref(null)
    const messages = ref([])
    const messagesLoaded = ref(false)
    const route = useRoute()
    const rooms = ref([])
    let apiChatItems = ref([])
    const showNotificationAlert = ref(false)

    const handleEnableNotifications = async () => {
      const permission = await requestNotificationPermission()
      if (permission === 'granted') {
        console.log('Notification permissions granted.')
      }
      hideNotificationAlert()
    }

    const hideNotificationAlert = () => {
      showNotificationAlert.value = false
    }

    const sendMessageReaction = content => {
      let reaction = content?.detail[0]
      let SelectedUser = JSON.parse(JSON.stringify(selectedUser.value))
      let OwnUser = JSON.parse(JSON.stringify(ownUser.value))
      let reactionMessage = reaction?.reaction?.unicode
      let Messages = JSON.parse(JSON.stringify(messages.value))

      let messageIndex = Messages.findIndex(message => message._id === reaction?.messageId)

      if (messageIndex !== -1) {
        if (!Messages[messageIndex].reactions) {
          Messages[messageIndex].reactions = {}
        }

        if (!Messages[messageIndex].reactions[reactionMessage]) {
          Messages[messageIndex].reactions[reactionMessage] = []
        }

        const userReactionIndex = Messages[messageIndex].reactions[reactionMessage].indexOf(OwnUser.email)

        if (userReactionIndex === -1) {
          Messages[messageIndex].reactions[reactionMessage].push(OwnUser.email)
        } else {
          Messages[messageIndex].reactions[reactionMessage].splice(userReactionIndex, 1)

          if (Messages[messageIndex].reactions[reactionMessage].length === 0) {
            delete Messages[messageIndex].reactions[reactionMessage]
          }
        }

        messages.value = Messages
        console.log(reactionMessage)
      }
    }

    const sendMessage = content => {
      let message = content?.detail[0]
      let SelectedUser = JSON.parse(JSON.stringify(selectedUser.value))
      let OwnUser = JSON.parse(JSON.stringify(ownUser.value))
      let isReplyMessage = message?.replyMessage === null ? false : true
      const newMessage = {
        _id: Math.floor(1000 + Math.random() * 9000).toString(),
        content: message.content,
        senderId: OwnUser.email,
        replyMessage: isReplyMessage ? JSON.parse(JSON.stringify(message?.replyMessage)) : null,
        username: OwnUser.title,
        avatar: user_avatar2,
        timestamp: new Date().toLocaleTimeString(),
        seen: true,
        disableReactions: true,
      }
      messages.value.push(newMessage)
      try {
        let response = postChatMessage(SelectedUser?.profile?.id, message.content)
        console.log(response)
      } catch (error) {
        console.error('Failed to send message:', error)
      }
    }

    const editMessage = async content => {
      try {
        const { messageId, newContent } = content.detail[0]
        const Messages = JSON.parse(JSON.stringify(messages.value))

        const messageIndex = Messages.findIndex(message => message._id === messageId)

        if (messageIndex !== -1) {
          Messages[messageIndex].content = newContent
          let chatMessages = JSON.parse(JSON.stringify(apiChatItems.value))

          let chatId = Messages[messageIndex]['_id']

          let originalChatObj = lodash.find(chatMessages, chat => chat.id === chatId)
          originalChatObj['message'] = newContent
          let response = await updateChatById(chatId, originalChatObj)
          console.log(response)
          messages.value = Messages
        }
      } catch (error) {
        console.log('Failed to edit message:', error)
      }
    }

    const deleteMessage = async content => {
      try {
        const { message } = content.detail[0]
        const Messages = JSON.parse(JSON.stringify(messages.value))

        const messageIndex = Messages.findIndex(Message => Message._id === message?._id)
        if (messageIndex !== -1) {
          Messages[messageIndex].deleted = true
          let chatId = Messages[messageIndex]['_id']
          let response = await deleteChatById(chatId)
          console.log(response)
          messages.value = Messages
        }
      } catch (error) {
        console.error('Failed to delete message:', error)
      }
    }

    onMounted(async () => {
      try {
        showLoader.value = true
        logo.value = await getToolbarLogoImage()
        let user = await retrieveBlob('selectedUser')
        let ownUserProfile = await retrieveBlob('ownUser')
        selectedUser.value = user
        ownUser.value = ownUserProfile

        //await requestNotificationPermission() // Request notification permissions here

        let chats = await getAllChats()
        console.log(chats)
        if (chats.length > 0) {
          let chat = lodash.find(chats, chat => chat?.userProfileId2 === user?.profile?.id || chat?.userProfileId1 === user?.profile?.id)

          if (chat) {
            let chatItems = []
            let chatMessages = await getChatById(chat?.id)
            chatMessages?.chatItems.items.forEach(chatItem => {
              chatItems.push(chatItem)
            })

            apiChatItems.value = chatItems

            messages.value = chatItems.map(chatItem => ({
              _id: chatItem.id,
              indexId: chatItem.version, // Assuming version is unique
              content: chatItem.message,
              senderId: chatItem.creatorProfile.id === user?.profile?.id ? user?.email : ownUserProfile?.email,
              username: chatItem.creatorProfile.id === ownUserProfile?.profile?.id ? ownUserProfile.title : user.title,
              avatar: chatItem.creatorProfile.id === ownUserProfile.id ? user_avatar2 : user_avatar,
              date: moment(chatItem.createdAt).format('YYYY-MM-DD'),
              timestamp: moment(chatItem.createdAt).format('hh:mm:ss A'),
              system: false,
              saved: true,
              distributed: true,
              seen: true,
              deleted: false,
              failure: false,
              disableActions: false,
              disableReactions: true,
            }))
          }
        }

        messagesLoaded.value = true

        rooms.value = [
          {
            roomId: '1',
            roomName: user?.title,
            avatar: user_avatar,
            users: [
              {
                _id: user?.email,
                username: user?.title,
                avatar: user_avatar,
              },
              {
                _id: ownUserProfile?.email,
                username: ownUserProfile?.title,
                avatar: user_avatar2,
              },
            ],
            typingUsers: [ownUserProfile?.email],
          },
        ]
        setTimeout(async () => {
          const permission = await checkNotificationPermission()
          if (permission !== 'granted') {
            showNotificationAlert.value = true // Show alert if permission not granted
          }
        }, 3000)
      } catch (error) {
        console.error('Failed to retrieve user data:', error)
      } finally {
        showLoader.value = false
      }
    })

    return {
      logo,
      showLoader,
      selectedUser,
      ownUser,
      messages,
      messagesLoaded,
      sendMessage,
      sendMessageReaction,
      editMessage,
      deleteMessage,
      route,
      rooms,
      showNotificationAlert,
      handleEnableNotifications,
      hideNotificationAlert,
    }
  },
})
</script>

<style scoped>
.chat-view {}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
}

.notification-alert {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 70%;
  padding: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  border-radius: 8px;
  animation: slideDown 0.4s ease-in-out;
  display: flex;
  justify-content: start;
  align-items: start;
}

@keyframes slideDown {
  0% {
    top: -50px;
    opacity: 0;
  }

  100% {
    top: 10px;
    opacity: 1;
  }
}

.alert-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.alert-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #1a237e;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 120px);
}

.toolbar-icon {
  height: 30px;
  align-self: center;
  margin-left: 1rem;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  /* Adjust according to your toolbar height */
}
</style>
<!--
Chat Component

This component provides a chat interface for users to communicate with each other. The main features include sending, editing, and deleting messages, as well as reacting to messages with emojis. The chat interface is designed to be user-friendly and responsive, ensuring a smooth user experience.

Functionalities:
1. Loader: Displays a loading spinner when data is being fetched or processed.
2. Notification Alert: Prompts users to enable notifications for the chat. Users can choose to enable or disable notifications by clicking the respective buttons.
3. Chat Interface: Displays the chat messages between users. Users can send new messages, edit existing messages, and delete messages. The chat interface also supports reactions to messages, although reactions are disabled in this implementation.
4. Message Input: Users can type and send messages using the input field at the bottom of the chat interface. The send button allows users to send their messages.
5. Visual Feedback: The component provides visual feedback for various actions. For example, when a message is successfully sent, it appears in the chat interface. If there is an error while sending a message, an error message is logged in the console.

Dynamic Behaviors:
1. Notification Permission: When the component is mounted, it checks if the user has granted notification permissions. If not, a notification alert is displayed, prompting the user to enable notifications.
2. Message Reactions: Users can react to messages by clicking on the reaction icons. The reactions are updated in real-time and displayed next to the respective messages.
3. Message Sending: When a user sends a message, it is added to the chat interface and sent to the server. If the message is successfully sent, it appears in the chat interface. If there is an error, an error message is logged in the console.
4. Message Editing: Users can edit their messages by clicking on the edit icon. The edited message is updated in the chat interface and sent to the server.
5. Message Deletion: Users can delete their messages by clicking on the delete icon. The deleted message is marked as deleted in the chat interface and removed from the server.

Visual Feedback:
1. Loader: Displays a loading spinner when data is being fetched or processed.
2. Notification Alert: Displays a notification alert at the top of the chat interface, prompting users to enable notifications.
3. Message Reactions: Displays reaction icons next to messages, indicating the reactions from users.
4. Message Sending: Displays the sent message in the chat interface. If there is an error, an error message is logged in the console.
5. Message Editing: Updates the edited message in the chat interface. If there is an error, an error message is logged in the console.
6. Message Deletion: Marks the deleted message as deleted in the chat interface. If there is an error, an error message is logged in the console.

Overall, this chat component provides a comprehensive and interactive chat experience for users, allowing them to communicate effectively and efficiently within the app.
-->
